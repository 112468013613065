var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-table-row',_vm._b({staticClass:"tariffs-table-item",attrs:{"columns":_vm.columns,"col-class":"tariffs-table-item__col"},scopedSlots:_vm._u([{key:"server",fn:function(){return [_c('div',{staticClass:"tariff-table-item__server"},[(![1, 4, 5].includes(_vm.item.status.code))?_c('router-link',{staticClass:"tariffs-table-item__link medium-text",attrs:{"to":{ name: 'vpsDetail', params: { id: _vm.item.id } }}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):_c('div',{staticClass:"tariffs-table-item medium-text"},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('div',{staticClass:"tariffs-table-item__specs note-text"},[_vm._v(" "+_vm._s(_vm.specs)+" ")])],1)]},proxy:true},{key:"id",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__ip note-color"},[_vm._v(" "+_vm._s(_vm.item.id)+" ")])]},proxy:true},{key:"ip",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__ip note-color"},[_vm._v(" "+_vm._s(_vm.item.ip)+" ")])]},proxy:true},{key:"pricelist",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__pricelist"},[_vm._v(" "+_vm._s(_vm.item.pricelist)+" ")])]},proxy:true},{key:"active",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__ip-promise"},[(_vm.item.promise !== 'null')?_c('plain-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.promiseOnItem,
          autoHide: false,
          placement: 'left-start',
          container: false,
        }),expression:"{\n          content: promiseOnItem,\n          autoHide: false,\n          placement: 'left-start',\n          container: false,\n        }"}],staticClass:"allowed-user-rights__promise",attrs:{"icon":"promise-icon"}}):_vm._e(),_c('div',{staticClass:"tariffs-table-item__date note-color promise"},[_vm._v(" "+_vm._s(_vm.$d(_vm.item.expiredate, 'short'))+" ")])],1),(_vm.hasProlongTool)?_c('plain-button',{staticClass:"tariffs-table-item__ip-prolong",attrs:{"color":"primary"},on:{"click":function($event){_vm.costAutoProlong === '0.00' ? _vm.singleProlongFree(_vm.item) : _vm.singleProlong(_vm.item)}}},[_vm._v(" "+_vm._s(_vm.$t('prolong.action'))+" ")]):_vm._e()]},proxy:true},{key:"cpu",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__cpu note-color"},[_vm._v(" "+_vm._s(_vm.item.ncpu)+" ")])]},proxy:true},{key:"mem",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__mem note-color"},[_vm._v(" "+_vm._s(_vm.item.mem)+" ")])]},proxy:true},{key:"disc",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__disc note-color"},[_vm._v(" "+_vm._s(_vm.item.disc)+" ")])]},proxy:true},{key:"autoprolong",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__icon"},[_c('vps-autoprolong',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.isAutoProlong,
          autoHide: false,
          placement: 'left-start',
        }),expression:"{\n          content: isAutoProlong,\n          autoHide: false,\n          placement: 'left-start',\n        }"}],staticClass:"tariffs-table-item__autoprolong",attrs:{"tariff":_vm.item}})],1),_c('div',{staticClass:"tariffs-table-item__icon"},[_vm._v(_vm._s(_vm.autoprolongLabel))])]},proxy:true},{key:"cost",fn:function(){return [_c('div',{staticClass:"tariffs-table-item__cost note-color"},[_vm._v(" "+_vm._s(_vm.$n(_vm.costAutoProlong, 'currency', _vm.$i18n.locale))+" ")])]},proxy:true},{key:"status",fn:function(){return [_c('main-label',{staticClass:"tariffs-table-item__status",attrs:{"color":_vm.item.status.color,"theme":"plain"}},[_vm._v(" "+_vm._s(_vm.$t(`status.${_vm.item.status.key}`))+" ")]),(_vm.item.status.code === 2 && !!_vm.serverState)?_c('main-label',{staticClass:"tariffs-table-item__status",staticStyle:{"display":"block"},attrs:{"color":_vm.item.serverState.color,"theme":"plain"}},[_vm._v(" "+_vm._s(_vm.$t(`status.${_vm.serverState.key}`))+" ")]):_vm._e()]},proxy:true},{key:"more",fn:function(){return [_c('v-popover',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closePopoverOnOutside),expression:"closePopoverOnOutside"}],attrs:{"placement":_vm.placement,"open":_vm.isPopoverOpen,"trigger":"manual","popover-class":"tariff-table-item__popover"},scopedSlots:_vm._u([{key:"popover",fn:function(){return [(_vm.isPopoverOpen)?_c('vps-context-menu',{staticClass:"tariff-table-item__context-menu",attrs:{"tariff":_vm.item}}):_vm._e()]},proxy:true}])},[_c('plain-button',{staticClass:"tariffs-table-item__more",attrs:{"icon":"more"},on:{"click":_vm.togglePopoverState}})],1)]},proxy:true}])},'base-table-row',_vm.item,false))
}
var staticRenderFns = []

export { render, staticRenderFns }