<template>
  <div class="tariffs-table">
    <base-table
      :columns="tableHead"
      :list="paginList"
      :sort="sort"
      class="tariffs-table__table"
      @sort-change="onSortChange"
    >
      <tariffs-table-row
        v-for="item in paginList"
        :key="item.id"
        :columns="tableHead"
        :item="item"
        :length="paginList.length"
        @click-more="$emit('click-more', item)"
      />
    </base-table>
    <new-pagination
      :step="pagin.step"
      :start="pagin.start || 0"
      :count="list.length"
      type="base"
      @set-step="setStep"
      @new-pagination="changePagination"
    />
  </div>
</template>

<script>
import BaseTable from '@/components/Table/BaseTable';
import TariffsTableRow from './TariffsTableRow';
import NewPagination from '@/components/Pagination/NewPagination';
import pagination from '@/mixins/pagination';
export default {
  name: 'TariffsTable',
  components: { BaseTable, TariffsTableRow, NewPagination },
  mixins: [pagination],
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableHead: [
        {
          key: 'id',
          label: this.$t('tableHead.id'),
          style: {
            width: '80px',
          },
          sort: {
            prop: 'id',
            order: 'asc',
          },
        },
        {
          key: 'server',
          label: this.$t('tableHead.server'),
          style: {
            //flex: '1 1 auto',
            width: '300px',
            maxWidth: '300px',
            verticalAlign: 'baseline',
          },
          sort: {
            prop: 'title',
            order: 'asc',
          },
        },
        {
          key: 'ip',
          label: this.$t('tableHead.ip'),
          style: {
            width: '134px',
            verticalAlign: 'baseline',
          },
        },
        {
          key: 'pricelist',
          label: this.$t('tableHead.pricelist'),
          style: {
            width: '100px',
            minWidth: '100px',
            verticalAlign: 'baseline',
          },
        },
        {
          key: 'active',
          label: this.$t('tableHead.active'),
          style: {
            width: '152px',
            verticalAlign: 'baseline',
          },
          sort: {
            prop: 'expiredate',
            order: 'asc',
          },
        },
        {
          label: this.$t('tableHead.ap'),
          key: 'autoprolong',
          style: {
            width: '44px',
          },
        },
        {
          key: 'cost',
          label: this.$t('tableHead.cost'),
          style: {
            width: '89px',
          },
          sort: {
            prop: 'cost',
            order: 'asc',
          },
        },
        {
          key: 'status',
          label: this.$t('tableHead.status'),
          style: {
            width: '115px',
          },
          sort: {
            prop: 'status',
            order: 'asc',
          },
        },
        {
          label: this.$t('tableHead.menu'),
          key: 'more',
          style: {
            width: '44px',
          },
        },
      ],
      /*stepOptions: [10, 25, 50, 100, 'all'],
      pagin: {
        start: 0,
        step: 10,
      },*/
    };
  },
  computed: {
    list() {
      return this.$store.getters['moduleVPS/GET_SORTED_LIST'];
    },
    sort() {
      return this.$store.state.moduleVPS.sort;
    },
    /*paginList() {
      if (this.pagin.step === 'all') return this.list;
      const { start, step } = this.pagin;
      const end = start + step;
      return this.list.slice(start, end);
    },*/
  },
  watch: {
    sort: {
      handler(val) {
        this.updateSort(val);
      },
      deep: true,
    },
  },
  mounted() {
    this.updateSort(this.sort);
  },
  methods: {
    onSortChange(payload) {
      let { prop, order } = payload;
      if (this.sort.prop === prop) order = order === 'asc' ? 'desc' : 'asc';
      this.$store.commit('moduleVPS/SET_SORT', { prop, order });
    },
    updateSort(sort) {
      const col = this.tableHead.find(i => i.sort && i.sort.prop === sort.prop);
      col.sort.order = sort.order;
    },
    /*setStep(value) {
      this.pagin.step = value;
    },
    changePagination(value) {
      this.pagin.step = value.rows;
      this.pagin.start = value.first;
    },*/
  },
};
</script>

<i18n>
  {
    "ru": {
      "tableHead": {
        "server": "Сервер",
        "ip": "IP",
        "id": "ID",
        "ap": "Автопродление",
        "pricelist": "Тариф",
        "active": "Действует до",
        "cpu": "CPU",
        "mem": "RAM",
        "disc": "HDD",
        "cost": "₽ / мес",
        "status": "Статус",
        "menu": "Меню"
      },
      "askSure": {
        "title": "Подтвердите действие",
        "text": "Вы уверены, что хотите {msg}?",
        "confirm": "Да",
        "cancel": "Отмена"
      },
      "activeUnder": "До {date}",
      "selectAll": "Выбрать все",
      "selected": "Выбрано 0 серверов | Выбран 1 сервер | Выбрано {n} сервера | Выбрано {n} серверов",
      "removeOrders": {
        "action": "Удалить заказы",
        "text": "удалить заказы",
        "success": "Заказы успешно удалены",
        "fail": "Что-то пошло не так, попробуйте повторить позднее",
        "ok": "Понятно"
      },
      "multiActionsDisabled": "Для выбранных услуг массовые действия недоступны",
      "prolong": {
        "action": "Продлить",
        "disabled": "Выбраны услуги, для которых продление недоступно",
        "title": "Продление тарифов",
        "error": "К сожалению, что-то пошло не так",
        "success_basket": "Продление тарифов {msg} успешно добавлено в корзину.",
        "success_order": "Продление тарифов {msg} прошло успешно. Данные скоро обновятся.",
        "error_result": "Продление тарифов {msg} не удалось.",
        "confirm": "Оплатить",
        "order": "В корзину"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table {
  width: 100%;

  &__pagination {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
